<template>
  <div class="form-checkbox">
    <input
      :id="checkboxId"
      :checked="checked"
      type="checkbox"
      :aria-checked="checked"
      :aria-disabled="disabled"
      :tabindex="!disabled && 0"
      :class="['checkmark', disabled && 'disabled', checked && 'checked']"
      :style="orgColors"
      @change="(event) => handleSelection(event.target.checked)"
      @keypress.enter.prevent="(event) => handleSelection(!event.target.checked)"
      @keypress.space.prevent="(event) => handleSelection(!event.target.checked)"
    />
    <!-- eslint-disable-next-line vuejs-accessibility/label-has-for -->
    <label
      :for="checkboxId"
      class="checkbox-label"
    >
      <slot />
    </label>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import tinycolor from 'tinycolor2';

export default {
  props: {
    modelValue: Boolean,
    disabled: Boolean,
    checkboxId: {
      type: String,
      default: '',
    },
  },
  emits: ['changed'],
  data() {
    return {
      checked: this.modelValue,
    };
  },
  computed: {
    ...mapState(['globals']),
    accessibleCheckboxContrast() {
      return tinycolor.isReadable(this.globals?.info?.primary_color?.hex, '#ffffff', {});
    },
    orgColors() {
      return {
        '--primary-color': this.accessibleCheckboxContrast ? this.globals?.info?.primary_color?.hex : '#662e80',
      };
    },
  },
  watch: {
    modelValue(newVal) {
      this.checked = newVal;
    },
  },
  methods: {
    handleSelection(value) {
      this.checked = value;
      this.$emit('changed', { checked: this.checked });
    },
  },
};
</script>

<style scoped lang="scss">
@import '../scss/color_vars';

.form-checkbox {
  --checkbox-error-color: #{$color-grey-50};
  --checkbox-background-color: #{$color-primary-white};
  --checkbox-border-color: #{$color-purple-40};
  --checkbox-selected-border-color: #{$color-primary-purple};
  --checkbox-disabled-background-color: #{$color-grey-20};
  --checkbox-disabled-border-color: #{$color-grey-30};

  display: flex;
  align-items: center;
  margin-bottom: 6px;

  .checkmark {
    border: 1px solid;
    border-color: var(--checkbox-border-color);
    background: var(--checkbox-background-color);
    display: inline-block;
    width: 16px;
    height: 16px;
    border-radius: 2px;
    appearance: none;

    &:after {
      content: '';
      display: none;
      position: relative;
      left: 4px;
      top: 0;
      width: 4px;
      height: 9px;
      border: solid var(--checkbox-background-color);
      border-width: 0 2px 2px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }

    &.checked {
      width: 16px;
      height: 16px;
      border: 1px solid;
      border-color: var(--primary-color);
      background-color: var(--primary-color);

      &:after {
        display: block;
      }
    }

    &.disabled {
      width: 16px;
      height: 16px;
      background-color: var(--checkbox-disabled-background-color);
      border: 1px solid;
      border-color: var(--checkbox-disabled-border-color);
    }
  }

  .checkbox-label {
    margin-left: 8px;
  }
}
</style>
